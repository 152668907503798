import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import {graphql, withPrefix } from 'gatsby'
import SEO from '../../components/seo'
import classNames from 'classnames'
import styles from './styles.module.scss'
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import Image from "../../components/Image";
import { getBodySections, getSectionHeading, getSectionItems } from '../../utils/helper'
import EducationDynamicsBannersBlock from "../../components/EducationDynamicsBanner";

const DegreePage = ({ data }) => {
  const {degreePage: page} = data;
  let bodySections = getBodySections(page.body);

  const crumbs = [
    {pathname: "/", crumbLabel: "Home"},
    {pathname: "/eddist.html", crumbLabel: "Degrees"},
    {pathname: withPrefix(page.categoryPages[0].categoryPagePath), crumbLabel: page.categoryPages[0].categoryPageName},
    {pathname: withPrefix(page.path), crumbLabel: page.name}
  ];

  return (
    <Layout>
      <SEO title={page.metaTags.title} description={page.metaTags.description}/>

      <CustomBreadcrumbs crumbs={crumbs} />

      <h1>{page.title}</h1>

      { !!page.image && <Image imgName={page.image} /> }

      <section className={styles.intro} dangerouslySetInnerHTML={{__html: page.intro}}/>

      <EducationDynamicsBannersBlock
          top
          categories={page.educationDynamicsCategory}
          subCategories={page.educationDynamicsSubCategory}
          specialties={page.educationDynamicsSpecialty}
      />

      {
        bodySections && Object.keys(bodySections) ? Object.keys(bodySections).map((key, index) => {
          return bodySections[key] && bodySections[key].length > 0 ? (
            <React.Fragment key={index}>
              <section className={classNames(styles[key], styles.bodySection)}>
                {key === 'faq' ?
                    <>
                      {getSectionHeading(bodySections[key])}
                      {getSectionItems(bodySections[key]).map((item, index) => (
                        <div className={styles.item} key={index}>{item}</div>
                      ))}
                    </>
                  :
                    bodySections[key]
                }
              </section>

              {key === 'degree-info' &&
                  // Show second EdDy banners block after main degree information if there is this "degree-info" section.
                  <EducationDynamicsBannersBlock
                      bottom
                      categories={page.educationDynamicsCategory}
                      subCategories={page.educationDynamicsSubCategory}
                      specialties={page.educationDynamicsSpecialty}
                  />
              }
            </React.Fragment>
          ) : ''
        }) : (
            <>
              <section className={styles.bodySection} dangerouslySetInnerHTML={{__html: page.body}} />

              {/* Show second Edy banners block after content if there is no "degree-info" section. */}
              <EducationDynamicsBannersBlock
                  bottom
                  categories={page.educationDynamicsCategory}
                  subCategories={page.educationDynamicsSubCategory}
                  specialties={page.educationDynamicsSpecialty}
              />
            </>
        )
      }
    </Layout>
  )
};

DegreePage.propTypes = {
  data: PropTypes.shape({
    degreePage: PropTypes.object
  })
};

export default DegreePage

export const pageQuery = graphql`
  query($id: String!) {
    degreePage(nid: {eq: $id}) {
      metaTags {
        title
        description
      }
      sections {
        section
      }
      categoryPages {
        categoryPage
        categoryPageName
        categoryPagePath
      }
      name
      title
      intro
      body
      image
      path
      educationDynamicsCategory
      educationDynamicsSubCategory
      educationDynamicsSpecialty
    }
  }
`;
